import React, { Component } from 'react';
import './Loader.scss';

import logo from '../../assets/logo.svg';

class Loader extends Component {
  render() {
    if (this.props.loading) {
      return (
        <div className="loader__box" id="loader__box">
          <img className="logo_img" src={logo} alt="Mitru La Paz" />
          <div className="inner">
            <div className="bg">
              <div className="el" />
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default Loader;
