import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from '../pages/Home';
import Rooms from '../pages/Rooms';
import Services from '../pages/Services';
import Offers from '../pages/Offers';
import Blog from '../pages/Blog';
import Contact from '../pages/Contact';
// import NotFound from './NotFound';

import '../i18n';

const Router = () => (
  <BrowserRouter forceRefresh={true}>
    <Switch>
      <Route path="/home/:lang" component={Home} />

      <Route path="/rooms/:lang/:roomName" component={Rooms} />
      <Route path="/services/:lang/:serviceName" component={Services} />
      <Route path="/offers/:lang/:offer" component={Offers} />
      <Route path="/blog/:lang/:slug" component={Blog} />
      <Route path="/contact/:lang" component={Contact} />

      <Redirect to="/home/es" />
    </Switch>
  </BrowserRouter>
);

export default Router;
