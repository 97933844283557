import React, { Component } from 'react';
import './Reserve.scss';

import { withNamespaces } from 'react-i18next';

// HTTP Request
import axios from 'axios';

// Icons from fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronDown);

class Reserve extends Component {
  closeBtn = React.createRef();

  // form
  adults = React.createRef();
  kids = React.createRef();
  arrival = React.createRef();
  leave = React.createRef();
  name = React.createRef();
  surname = React.createRef();
  country = React.createRef();
  city = React.createRef();
  phone = React.createRef();
  email = React.createRef();
  message = React.createRef();

  state = {
    suites: [],
    sent: false,
    canSend: false
  };

  componentDidMount() {
    document.querySelector('body').style = 'overflow: hidden';

    const api =
      'https://www.hotelnclapaz.com/restapi/public/api';
    // get rooms
    axios
      .get(`${api}/webroom`)
      .then(response => {
        const suites = response.data.result.data;
        this.setState({ suites });
      })
      .catch(error => {
        console.log(error);
      });
  }

  closeModal = () => {
    this.props.reserveModal.current.classList.remove('modal-reserve-up');
    this.props.reserveModal.current.classList.add('modal-reserve-down');
  };

  handleChange = event => {
    const adults = this.adults.current.value;
    const kids = this.kids.current.value;
    const arrival = this.arrival.current.value;
    const leave = this.leave.current.value;
    const name = this.name.current.value;
    const surname = this.surname.current.value;
    const country = this.country.current.value;
    const city = this.city.current.value;
    const phone = this.phone.current.value;
    const email = this.email.current.value;
    const message = this.message.current.value;

    if (
      adults !== null &&
      adults !== '' &&
      kids !== null &&
      kids !== '' &&
      arrival !== null &&
      arrival !== '' &&
      leave !== null &&
      leave !== '' &&
      name !== null &&
      name !== '' &&
      surname !== null &&
      surname !== '' &&
      email !== null &&
      email !== ''
    ) {
      return this.setState({ canSend: true });
    } else {
      return this.setState({ canSend: false });
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({ canSend: true });

    const {
      room,
      adults,
      kids,
      name,
      surname,
      country,
      city,
      phone,
      email,
      message,
      arrive,
      leave
    } = event.target;

    const data = {
      mailingcategory_id : 4,
      fields:{
        room: room.value,
        adults: adults.value,
        kids: kids.value,
        arrival: arrive.value,
        leave: leave.value,
        name: name.value,
        surname: surname.value,
        country: country.value,
        city: city.value,
        phone: phone.value,
        email: email.value,
        message: message.value
      }
     
    };
    var params = {
      mailingcategory_id : 4,
      fields:{
        room: room.value,
        adults: adults.value,
        kids: kids.value,
        arrival: arrive.value,
        leave: leave.value,
        name: name.value,
        surname: surname.value,
        country: country.value,
        city: city.value,
        phone: phone.value,
        email: email.value,
        message: message.value
      }
    }

    console.log('form sending', data);
    axios.post('https://www.hotelnclapaz.com/restapi/public/api/sendform', params)
     .then(function(response){
       console.log(response);
       //Perform action based on response
   })
     .catch(function(error){
       console.log(error);
       //Perform action based on error
     });
   /* fetch(
      'https://www.hotelnclapaz.com/restapi/public/api/sendform',
      { 
        method: 'POST', 
        body: data 
      }
    )
    .then((response) => response.json())
    .then((responseText) => {
      console.log('EXIT',responseText);
    })*/
    /*.then(function(res) {
      console.log('EXIT',res);
    });*/

    event.target.reset();

    this.setState({ sent: true });
  };

  render() {
    const { t } = this.props;

    if (!this.state.suites.length > 0) {
      return <div />;
    }

    if (this.state.sent) {
      return (
        <div
          className="Reserve modal-reserve-down"
          ref={this.props.reserveModal}>
          <div className="Reserve__header">
            <h4>{t('desc.header')}</h4>
            <button className="no-border" onClick={this.closeModal}>
              <FontAwesomeIcon icon={['fas', 'chevron-down']} />
            </button>
          </div>
          <div className="Reserve__body">
            <p className="p-1">{t('form.thanks')}</p>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.setState({ sent: false });
                this.props.reserveModal.current.classList.remove(
                  'modal-reserve-up'
                );
                this.props.reserveModal.current.classList.add(
                  'modal-reserve-down'
                );
              }}>
              {t('form.continue')}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="Reserve modal-reserve-down" ref={this.props.reserveModal}>
        <div className="Reserve__header">
          <h4>{t('desc.header')}</h4>
          <button className="no-border" onClick={this.closeModal}>
            <FontAwesomeIcon icon={['fas', 'chevron-down']} />
          </button>
        </div>
        <div className="Reserve__body">
          {/* Form */}
          <form
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            noValidate>
            <div className="grid__form grid__form--header">
              <select
                name="room"
                defaultValue={this.props.suite.name[this.props.match]}>
                {this.state.suites.map(item => {
                  return (
                    <option key={item.id} value={item.name[this.props.match]}>
                      {item.name[this.props.match]} ($us. {item.price})
                    </option>
                  );
                })}
              </select>

              <p> {t('form.perNight')}</p>
            </div>

            <p className="info">{t('form.warning')}</p>


            <div className="grid__form--calendar">
              <label htmlFor="arrive" className="label-large">
                *{t('form.arrive')}
                <input
                  type="date"
                  id="arrive"
                  ref={this.arrival}
                  name="arrive"
                  required
                />
              </label>
              <label htmlFor="leave" className="label-large">
                *{t('form.leave')}
                <input
                  type="date"
                  id="leave"
                  ref={this.leave}
                  name="leave"
                  required
                />
              </label>
              <label htmlFor="adults">
                {t('form.adults')}
                <input
                  type="number"
                  id="adults"
                  name="adults"
                  ref={this.adults}
                  required
                  defaultValue="0"
                  min="0"
                  max="2"
                />
              </label>
              <label htmlFor="kids">
                {t('form.kids')}
                <input
                  type="number"
                  id="kids"
                  name="kids"
                  ref={this.kids}
                  required
                  defaultValue="0"
                  min="0"
                  max="2"
                />
              </label>
            </div>

            <div className="grid__form">
              {/* Name */}
              <input
                type="text"
                className="custom-input"
                placeholder={t('form.name')}
                ref={this.name}
                id="name"
                name="name"
                required
              />
              {/* Surname */}
              <input
                type="text"
                className="custom-input"
                placeholder={t('form.surname')}
                ref={this.surname}
                id="surname"
                name="surname"
                required
              />
              {/* Country */}
              <input
                type="text"
                className="custom-input"
                placeholder={t('form.country')}
                id="country"
                name="country"
                ref={this.country}
              />
              {/* City */}
              <input
                type="text"
                className="custom-input"
                placeholder={t('form.city')}
                id="city"
                name="city"
                ref={this.city}
              />
              {/* Phone */}
              <input
                type="text"
                className="custom-input"
                placeholder={t('form.phone')}
                id="phone"
                name="phone"
                ref={this.phone}
              />
              {/* Email */}
              <input
                type="email"
                className="custom-input"
                required
                placeholder={t('form.email')}
                id="email"
                name="email"
                ref={this.email}
              />
            </div>
            <textarea
              className="custom-input"
              placeholder={t('form.message')}
              id="message"
              name="message"
              ref={this.message}
            />
            {/* textarea */}
            <p>{t('form.warning2')} 
            <a href={"mailto:" + "reservas@hotelnclapaz.com"}>
              reservas@hotelnclapaz.com
            </a>
            </p>
            <button
type="submit"
disabled={!this.state.canSend} className="btn btn-primary">
              {t('form.submit')}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default withNamespaces('translation')(Reserve);
