import React, { Component } from 'react';
import './ButtonReserve.scss';

import { withNamespaces } from 'react-i18next';

import axios from 'axios';
// Icons from fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronUp, faChevronDown);

const screen = window.matchMedia('(max-width: 768px)');

class ButtonReserve extends Component {
  closeBtn = React.createRef();

  state = {
    condition: false,
    suite: [],
    reserve: false,
  };

  componentDidMount() {
    document.querySelector('body').style = 'overflow: hidden';

    if (screen.matches) {
      this.setState({ condition: true });
    }
    

  }


  openReserve = () => {

    var elems = document.querySelectorAll(".Reserve");
    [].forEach.call(elems, function(el) {
      el.classList.remove("modal-reserve-down");
      el.classList.add("modal-reserve-up");
    });
    console.log('execute new component!!!',elems);
  };

  render() {
    const { t } = this.props;
    
      return (
        <div >
            <a 
            style={{ opacity: 1 }}
            onClick={this.openReserve}>
              {t('home.reserve')}
            </a>
        </div>
      );
    
  }

}

export default withNamespaces('translation')(ButtonReserve);