import React, { Component } from 'react';

// import header
import Nav from '../components/Nav/Nav';
import Loader from '../components/Loader/Loader';

import axios from 'axios';

// aseets
import background from '../assets/foto-mitru-02.jpg';
import background2x from '../assets/foto-mitru-02.jpg';

import { withNamespaces } from 'react-i18next';

import { Helmet } from 'react-helmet';
import metaImg from '../assets/default.jpg';

// Components
import ContactPopUp from '../components/PopUp/ContactPopUp';
import Reserve from '../components/Reserve/Reserve';
import FormContact from '../components/Reserve/FormContact';

class Contact extends Component {
  reserveModal = React.createRef();
  contactModal = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      reserve: false,
      res: []
    };
  }

  componentDidMount() {
    document.querySelector('html').style = 'overflow: hidden';
    document.querySelector('body').style = 'overflow: hidden';

    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);

    const api =
      'https://www.hotelnclapaz.com/restapi/public/api';

    axios
      .post(`${api}/webcontent/getdata`,{})
      .then(response => {
        const res = response.data.result.data;
        console.log('response',response);
        // Before passit to state extract what I need :)
        this.setState({ res });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const url = window.location.href;
    const { t } = this.props;

    if (!this.state.res.length > 0) {
      return <div />;
    }

    if (this.state.loading) {
      return <Loader loading={this.state.loading} />;
    }

    return (
      <React.Fragment>
        <style jsx="true">
          {`
            html,
            body {
              overflow: hidden;
              position: relative;
            }
          `}
        </style>

        <Helmet>
          <title>Contact | Hotel NC La Paz</title>
          {/* SEO tags */}
          <meta property="og:title" content="Contact | Hotel NC La Paz" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta property="og:image" content={metaImg} />
          <meta
            property="og:description"
            content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
          />
          <meta
            property="og:site_name"
            content="Contact | Hotel NC La Paz"
          />
          <meta itemprop="name" content="Contact | Hotel NC La Paz" />
          <meta
            itemprop="description"
            content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
          />
          <meta itemprop="image" content={metaImg} />
        </Helmet>

        <Nav
          match={this.props.match.params.lang}
          history={this.props.history}
        />

        {/* Contact Wrap */}
        <style jsx="true">
          {`
            .contact__wrapper {
              background: url(${background});
              background-size: cover;
            }
            @media screen and (-webkit-min-device-pixel-ratio: 2) {
              .contact__wrapper {
                background: url(${background2x});
                background-size: cover;
              }
            }
          `}
        </style>
        <div className="contact__wrapper">
          <h4 className="text-uppercase">{t('desc.contact')}</h4>
          <div className="flex-grid">
            <div className="col-map">
              <iframe
                src="https://snazzymaps.com/embed/122146"
                title="Hotel NC"
                className="map"
                height="400px"
                style={{ border: 'none' }}
              />
            </div>
            <div className="col-popUp" />
          </div>
          <ContactPopUp
            reserveModal={this.reserveModal}
            contactModal={this.contactModal}
            locationContent2={
              this.state.res[9].content[this.props.match.params.lang]
            }
            locationContent={
              this.state.res[1].content[this.props.match.params.lang]
            }
            phoneNumber={
              this.state.res[2].content[this.props.match.params.lang]
            }
            phoneNumber2={
              this.state.res[3].content[this.props.match.params.lang]
            }
          />
          <Reserve
            price={this.state.res.price}
            reserve={this.state.reserve}
            reserveModal={this.reserveModal}
            suite={{ name: { es: 'Suite', en: 'Suite' } }}
            match={this.props.match.params.lang}
          />
          <FormContact
            price={this.state.res.price}
            reserve={this.state.reserve}
            contactModal={this.contactModal}
            match={this.props.match.params.lang}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces('translation')(Contact);
