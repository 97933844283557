import React, { Component } from 'react';
import './PopUp.scss';

import { withNamespaces } from 'react-i18next';

// Icons from fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronDown);

const screen = window.matchMedia('(max-width: 768px)');

class PopUp extends Component {
  closeBtn = React.createRef();
  popUp = React.createRef();

  state = {
    condition: false
  };

  componentDidMount() {
    if (screen.matches) {
      this.setState({ condition: true });
    }
  }

  closePopUp = () => {
    this.setState({ condition: !this.state.condition });
    // Breaking the law
    document.querySelector('body').style = 'overflow: hidden';
  };

  openReserve = () => {
    this.props.reserveModal.current.classList.remove('modal-reserve-down');
    this.props.reserveModal.current.classList.add('modal-reserve-up');
  };

  openContact = () => {
    this.props.contactModal.current.classList.remove('modal-reserve-down');
    this.props.contactModal.current.classList.add('modal-reserve-up');
  };

  render() {
    const { t } = this.props;

    return (
      <div
        className={this.state.condition ? 'PopUp is-down' : 'PopUp is-up'}
        ref={this.popUp}
      >
        <div className="PopUp__header">
          <h4>{t('desc.detail')}</h4>
          {this.state.condition ? (
            <button className="no-border" onClick={this.closePopUp}>
              <FontAwesomeIcon icon={['fas', 'chevron-up']} />
            </button>
          ) : (
            <button className="no-border" onClick={this.closePopUp}>
              <FontAwesomeIcon icon={['fas', 'chevron-down']} />
            </button>
          )}
        </div>
        <div className="PopUp__body">
          <p className="text-capitalize">
          { this.props.locationContent2 }
          </p>
          <p className="text-capitalize">
            {t('form.location')}: {this.props.locationContent}
          </p>
          <p className="text-capitalize">
            {t('form.phone')}: {this.props.phoneNumber} 
          </p>

          <p className="text-capitalize">
            {t('form.phone2')}:  {this.props.phoneNumber2}
          </p>
          <p className="text-capitalize">
            {t('form.email2')}:{' '}
            <a
              className="text-lowercase"
              href="mailto:reservas@hotelnclapaz.com"
            >
              reservas@hotelnclapaz.com
            </a>
          </p>
          <button onClick={this.openReserve} className="btn btn-primary">
            {t('home.reserve')}
          </button>
          <button onClick={this.openContact} className="btn btn-primary mt-3">
            {t('desc.contact')}
          </button>
        </div>
      </div>
    );
  }
}

export default withNamespaces('translation')(PopUp);
