import React, { Component } from 'react';
import './PopUp.scss';

import { withNamespaces } from 'react-i18next';
import axios from 'axios';
import renderHTML from 'react-render-html';

// Icons from fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronUp, faChevronDown);

const screen = window.matchMedia('(max-width: 768px)');

class PopUp extends Component {
  closeBtn = React.createRef();
  popUp = React.createRef();

  state = {
    condition: false
  };

  componentDidMount() {
    document.querySelector('body').style = 'overflow: hidden';

    if (screen.matches) {
      this.setState({ condition: true });
    }
  }

  closePopUp = () => {
    this.setState({ condition: !this.state.condition });
    // Breaking the law
    document.querySelector('body').style = 'overflow: hidden';
  };

  openReserve = () => {
    this.props.reserveModal.current.classList.remove('modal-reserve-down');
    this.props.reserveModal.current.classList.add('modal-reserve-up');
  };

  render() {
    const { t } = this.props;

    if (this.props.button) {
      return (
        <div
          className={this.state.condition ? 'PopUp is-down' : 'PopUp is-up'}
          ref={this.popUp}
        >
          <div className="PopUp__header">
            <h4>
            {(this.props.title)?this.props.title+' - ':'.'}  
            {t('desc.detail')}
            </h4>
            {this.state.condition ? (
              <button className="no-border" onClick={this.closePopUp}>
                <FontAwesomeIcon icon={['fas', 'chevron-up']} />
              </button>
            ) : (
              <button className="no-border" onClick={this.closePopUp}>
                <FontAwesomeIcon icon={['fas', 'chevron-down']} />
              </button>
            )}
          </div>
          <div className="PopUp__body">

        
            <p>     
            {renderHTML(this.props.content)}
            </p>
       
            <button onClick={this.openReserve} className="btn btn-primary mt-2">
              {t('home.reserve')}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={this.state.condition ? 'PopUp is-down' : 'PopUp is-up'}
          ref={this.popUp}
        >
          <div className="PopUp__header">
            <h4>
            {(this.props.title)?this.props.title+' ':'.'}
            </h4>
            {this.state.condition ? (
              <button className="no-border" onClick={this.closePopUp}>
                <FontAwesomeIcon icon={['fas', 'chevron-up']} />
              </button>
            ) : (
              <button className="no-border" onClick={this.closePopUp}>
                <FontAwesomeIcon icon={['fas', 'chevron-down']} />
              </button>
            )}
          </div>
          <div className="PopUp__body">{renderHTML(this.props.content)}</div>
        </div>
      );
    }
  }
}

export default withNamespaces('translation')(PopUp);
