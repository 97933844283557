import React, { Component } from 'react';

// import header
import Nav from '../components/Nav/Nav';

import renderHTML from 'react-render-html';

// HTTP Request
import axios from 'axios';

import { withNamespaces } from 'react-i18next';

// Icons from fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faShareAlt } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from 'react-helmet';

// Share buttons
import {
  Twitter,
  Facebook,
  Google,
  Mail,
  Whatsapp,
  Telegram
} from 'react-social-sharing';

library.add(faShareAlt);

class Blog extends Component {
  social = React.createRef();

  state = {
    blogpost: [],
    posts: []
  };

  componentDidMount() {
    const api =
      'https://www.hotelnclapaz.com/restapi/public/api';

    if (this.props.match.params.lang === 'es') {
      axios
        .get(`${api}/webblog`)
        .then(response => {
          const blogpost = response.data.result.data.find(
            item => item.title.es === this.props.match.params.slug
          );
          this.setState({
            blogpost,
            posts: response.data.result.data
          });
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (this.props.match.params.lang === 'en') {
      axios
        .get(`${api}/webblog`)
        .then(response => {
          const blogpost = response.data.result.data.find(
            item => item.title.en === this.props.match.params.slug
          );
          this.setState({
            blogpost,
            posts: response.data.result.data
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  showSocial = () => {
    this.social.current.classList.toggle('active');
  };

  goToBlogpost = route => {
    // this.props.history.push(`/blog/${this.props.match.params.lang}/${route}`);
    window.location = `/blog/${this.props.match.params.lang}/${route}`;
  };

  render() {
    const { t } = this.props;
    const url = window.location.href;

    if (!this.state.posts.length > 0) {
      return <div />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.match.params.slug} | Hotel NC La Paz</title>

          {/* SEO tags */}
          <meta
            property="og:title"
            content={`${this.props.match.params.slug} | Hotel NC La Paz`}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta
            property="og:image"
            content={`https://www.hotelnclapaz.com/restapi/public${
              this.state.blogpost.image
            }`}
          />
          <meta
            property="og:description"
            content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
          />
          <meta
            property="og:site_name"
            content={`${this.props.match.params.slug} | Hotel NC La Paz`}
          />

          <meta
            itemprop="name"
            content={`${this.props.match.params.slug} | Hotel NC La Paz`}
          />
          <meta
            itemprop="description"
            content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
          />
          <meta
            itemprop="image"
            content={`https://www.hotelnclapaz.com/restapi/public${
              this.state.blogpost.image
            }`}
          />
        </Helmet>

        <Nav
          match={this.props.match.params.lang}
          history={this.props.history}
        />

        {/* Blog Content */}
        <div className="blog__wrapper">
          <h2>{this.props.match.params.slug}</h2>
          <img
            src={`https://www.hotelnclapaz.com/restapi/public${
              this.state.blogpost.portrait
            }`}
            alt="Cover Blogpost"
          />
        </div>
        <div className="container">
          <article>
            {renderHTML(
              this.state.blogpost.content[this.props.match.params.lang]
            )}
            <button
              className="btn btn-primary button--medium"
              onClick={this.showSocial}
            >
              <FontAwesomeIcon icon={['fas', 'share-alt']} />
            </button>

            <div className="share__box" ref={this.social}>
              <Twitter solid small message="Check this out!" link={url} />
              <Facebook solid small link={url} />
              <Google solid small link={url} />
              <Mail solid small message="Check this out!" link={url} />
              <Whatsapp solid small message="Share on Whatsapp" link={url} />
              <Telegram solid small message="Share on Telegram" link={url} />
            </div>
          </article>

          <hr className="spacer" />

          <div className="grid__blog">
            {this.state.posts.map(post => {
              return (
                <div className="card__blog">
                  <img
                    src={`https://www.hotelnclapaz.com/restapi/public${
                      post.image
                    }`}
                    alt={post.title[this.props.match.params.lang]}
                  />
                  <h4>{post.title[this.props.match.params.lang]}</h4>
                  {
                    // <WordLimit limit={150}>
                    //   {post.content[this.props.match.params.lang]}
                    // </WordLimit>
                  }
                  <button
                    className="btn btn-primary button--large mt-1"
                    onClick={() =>
                      this.goToBlogpost(
                        post.title[this.props.match.params.lang]
                      )
                    }
                  >
                    {t('desc.seeMore')}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces('translation')(Blog);

// {
//   this.state.content > MAX_LENGTH ? (
//     <div>
//       {`${this.state.content.substring(0, MAX_LENGTH)}...`}
//       <a href="#">Read more</a>
//     </div>
//   ) : (
//     <p>{this.state.content}</p>
//   )
// }
