import React from 'react';
import ReactDOM from 'react-dom';
import Router from './components/Router';
import * as serviceWorker from './serviceWorker';
import './scss/general.scss';
import axios from 'axios';
ReactDOM.render(<Router />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
const api = 'https://www.hotelnclapaz.com/restapi/public/api';
    // get rooms
    axios
    .post(`${api}/webcontent`)
    .then(response => {
        console.log('webcontent', response);
        const webcontent  = 'hello';
    })
    .catch(error => {
        console.log(error);
    });*/
