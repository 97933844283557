import React, { Component } from 'react';

import Nav from '../components/Nav/Nav';
import Loader from '../components/Loader/Loader';

// HTTP Request
import axios from 'axios';
import SlideImage from '../components/SlideImage';

import { withNamespaces } from 'react-i18next';

// Components
import PopUp from '../components/PopUp/PopUp';

// Logo Restaurant
import logo from '../assets/marca-restaurant.png';

import { Helmet } from 'react-helmet';

class Services extends Component {
  reserveModal = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      service: [],
      reserve: false,
      loading: true
    };
  }

  componentDidMount() {
    document.querySelector('html').style = 'overflow: hidden';
    document.querySelector('body').style = 'overflow: hidden';

    // lang by default
    if (
      this.props.match.params.lang === 'es' ||
      this.props.match.params.lang === 'en'
    ) {
      this.props.i18n.changeLanguage(this.props.match.params.lang);
    } else {
      this.props.history.push('/home/es');
      return console.log('Lang Not Available');
    }

    const api =
      'https://www.hotelnclapaz.com/restapi/public/api';

    if (this.props.match.params.lang === 'en') {
      axios
        .get(`${api}/webservice`)
        .then(response => {
          const res = response.data.result.data.find(
            item =>
              item.name[this.props.match.params.lang] ===
              this.props.match.params.serviceName
          );
          const content = res.content.en;
          const service = Object.keys(res.webserviceimage).map(function(key) {
            return res.webserviceimage[key];
          });

          this.setState({ service, content , res});
          console.log('dsadasda',this.state.res.name);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (this.props.match.params.lang === 'es') {
      axios
        .get(`${api}/webservice`)
        .then(response => {
          const res = response.data.result.data.find(
            item =>
              item.name[this.props.match.params.lang] ===
              this.props.match.params.serviceName
          );
          const content = res.content.es;
          const service = Object.keys(res.webserviceimage).map(function(key) {
            return res.webserviceimage[key];
          });
          this.setState({ service, content });
        })
        .catch(error => {
          console.log(error);
        });
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, 0);
  }

  render() {
    const url = window.location.href;

    if (!this.state.service.length > 0) {
      return <div />;
    }

    if (this.state.loading) {
      return <Loader loading={this.state.loading} />;
    }

    return (
        <React.Fragment>
          <style jsx>
            {`
              html,
              body {
                overflow: hidden;
                position: relative;
              }
            `}
          </style>
          <Helmet>
            <title>
              {this.props.match.params.serviceName} | Hotel NC La Paz
            </title>

            {/* SEO tags */}
            <meta
              property="og:title"
              content={`${
                this.props.match.params.serviceName
              } | Hotel NC La Paz`}
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta
              property="og:image"
              content={`https://www.hotelnclapaz.com/restapi/public${
                this.state.service[0].image
              }`}
            />
            <meta
              property="og:description"
              content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
            />
            <meta
              property="og:site_name"
              content={`${
                this.props.match.params.serviceName
              } | Hotel NC La Paz`}
            />
            <meta
              itemprop="name"
              content={`${
                this.props.match.params.serviceName
              } | Hotel NC La Paz`}
            />
            <meta
              itemprop="description"
              content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
            />
            <meta
              itemprop="image"
              content={`https://www.hotelnclapaz.com/restapi/public${
                this.state.service[0].image
              }`}
            />
          </Helmet>

          <Nav
            match={this.props.match.params.lang}
            history={this.props.history}
          />

          <section className="body">
            <PopUp
              reserveModal={this.reserveModal}
              title={this.props.match.params.serviceName}
              content={this.state.content}
              button={false}
            />
            <SlideImage data={this.state.service} />
          </section>
        </React.Fragment>
      );
    }
}

export default withNamespaces('translation')(Services);
