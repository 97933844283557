import React from 'react';

import { Fade } from 'react-slideshow-image';

const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true
};

const SlideImage = props => {
  return (
    <Fade {...fadeProperties}>
      {props.data.map(item => {
        return (
          <div className="each-fade" key={item.id}>
            <div className="image-container">
              <img
                src={`https://www.hotelnclapaz.com/restapi/public${
                  item.image
                }`}
                alt={item.name}
              />
            </div>
          </div>
        );
      })}
    </Fade>
  );
};

export default SlideImage;
