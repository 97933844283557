import React, { Component } from 'react';
import './Reserve.scss';

import { withNamespaces } from 'react-i18next';

// HTTP Request
import axios from 'axios';

// Icons from fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronDown);

class FormContact extends Component {
  closeBtn = React.createRef();

  // form
  subject = React.createRef();
  name = React.createRef();
  country = React.createRef();
  phone = React.createRef();
  email = React.createRef();
  message = React.createRef();

  state = {
    sent: false,
    canSend: false
  };

  componentDidMount() {
    document.querySelector('body').style = 'overflow: hidden';
  }

  closeModal = () => {
    this.props.contactModal.current.classList.remove('modal-reserve-up');
    this.props.contactModal.current.classList.add('modal-reserve-down');
  };

  handleChange = event => {
    const subject = this.subject.current.value;
    const name = this.name.current.value;
    const country = this.country.current.value;
    const phone = this.phone.current.value;
    const email = this.email.current.value;
    const message = this.message.current.value;

    if (
      subject !== null &&
      subject !== '' &&
      name !== null &&
      name !== '' &&
      country !== null &&
      country !== '' &&
      phone !== null &&
      phone !== '' &&
      email !== null &&
      email !== '' &&
      message !== null &&
      message !== ''
    ) {
      return this.setState({ canSend: true });
    } else {
      return this.setState({ canSend: false });
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    const { subject, name, country, phone, email, message } = event.target;

    const data = {
      subject: subject.value,
      name: name.value,
      country: country.value,
      phone: phone.value,
      email: email.value,
      message: message.value
    };

    console.log(data);
    var params = {
      mailingcategory_id :3,
      fields:{
          subject: subject.value,
          name: name.value,
          country: country.value,
          phone: phone.value,
          email: email.value,
          message: message.value
      }
    }

    console.log('form sending', data);
    axios.post('https://www.hotelnclapaz.com/restapi/public/api/sendform', params)
     .then(function(response){
       console.log(response);
       //Perform action based on response
   })
     .catch(function(error){
       console.log(error);
       //Perform action based on error
     });
       //P
    /*fetch(
      'https://www.hotelnclapaz.com/restapi/public/api/sendform',
      { method: 'POST', body: data }
    );*/

    event.target.reset();

    this.setState({ sent: true });
  };

  render() {
    const { t } = this.props;

    if (this.state.sent) {
      return (
        <div
          className="Reserve modal-reserve-down"
          ref={this.props.contactModal}>
          <div className="Reserve__header">
            <h4>{t('desc.contactTitle')}</h4>
            <button className="no-border" onClick={this.closeModal}>
              <FontAwesomeIcon icon={['fas', 'chevron-down']} />
            </button>
          </div>
          <div className="Reserve__body">
            <p className="p-1">{t('form.thanks')}</p>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.setState({ sent: false });
                this.props.contactModal.current.classList.remove(
                  'modal-reserve-up'
                );
                this.props.contactModal.current.classList.add(
                  'modal-reserve-down'
                );
              }}>
              {t('form.continue')}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="Reserve contact modal-reserve-down" ref={this.props.contactModal}>
        <div className="Reserve__header">
          <h4>{t('desc.contactTitle')}</h4>
          <button className="no-border" onClick={this.closeModal}>
            <FontAwesomeIcon icon={['fas', 'chevron-down']} />
          </button>
        </div>
        <div className="Reserve__body">
          {/* Form */}
          <form
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            noValidate>
            <input
              type="text"
              className="custom-input"
              placeholder={t('form.subject')}
              id="subject"
              name="subject"
              ref={this.subject}
              required
            />

            <div className="grid__form">
              {/* Input */}
              <input
                type="text"
                className="custom-input"
                placeholder={t('form.name')}
                id="name"
                name="name"
                ref={this.name}
                required
              />
              {/* Input */}
              <input
                type="text"
                className="custom-input"
                placeholder={t('form.country')}
                id="country"
                name="country"
                ref={this.country}
                required
              />
              {/* Input */}
              <input
                type="text"
                className="custom-input"
                placeholder={t('form.phone')}
                id="phone"
                name="phone"
                ref={this.phone}
                required
              />
              {/* Input */}
              <input
                type="email"
                className="custom-input"
                placeholder={t('form.email')}
                id="email"
                name="email"
                ref={this.email}
                required
              />
            </div>
            <textarea
              className="custom-input"
              placeholder={t('form.message')}
              id="message"
              name="message"
              ref={this.message}
              required
            />
            {/* textarea */}
            <button
              disabled={!this.state.canSend}
              className="btn btn-primary mb-3">
              {t('form.submit')}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default withNamespaces('translation')(FormContact);
