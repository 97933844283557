import React, { Component } from 'react';
import './Modal.scss';

import { withNamespaces } from 'react-i18next';

// Icons from fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faTimes);

class Modal extends Component {
  closeBtn = React.createRef();
  modal = React.createRef();

  closeModal = () => {
    this.modal.current.classList.add('cmodal--hide');
  };

  render() {
    const { t } = this.props;

    if (!this.props.offer || this.props.offer.length==0) {
      return <div />;
    }
  else{
      return (
          <div className="cmodal" ref={this.modal}>
            <button
              className="cmodal__close"
              onClick={this.closeModal}
            ref={this.closeBtn}
            >
            <FontAwesomeIcon icon={['fas', 'times']} />
            </button>
            <div className="cmodal__header--image">
                <img
            src={`https://www.hotelnclapaz.com/restapi/public/${
                this.props.offer[0].image
            }`}
            alt="Offer Img"
                />
                </div>
                <div className="cmodal__body">
                <h4>{this.props.offer[0].title[this.props.match]}</h4>
                <a href="/offers/es/Noche%20de%20bodas" className="btn btn-primary">
                {t('desc.cta')}
                </a>
                </div>
                </div>
            );
    }


  }
}

export default withNamespaces('translation')(Modal);
