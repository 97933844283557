import React from 'react';
import './CardBox.scss';

// Icons from fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronLeft);

const CardBox = props => {
  return (
    <React.Fragment>
      <h5 className="back text-center mb-4" onClick={props.goBack}>
        <span className="icons">
          <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        </span>{' '}
        Back
      </h5>
      <div className="cards__box">
        {props.array.map(item => {
          return (
            <div
              className="card"
              key={item.id}
              onClick={e => props.goTo(e, item.name[props.lang], props.route)}
            >
              <img
                src={`https://www.hotelnclapaz.com/restapi/public/${
                  item.image
                }`}
                alt={item.name[props.lang]}
              />
              <p>{item.name[props.lang]}</p>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default CardBox;
