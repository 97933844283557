import React, { Component } from 'react';

// import header
import Nav from '../components/Nav/Nav';
import Loader from '../components/Loader/Loader';
// HTTP Request
import axios from 'axios';

import { withNamespaces } from 'react-i18next';

// Components

import ButtonReserve from '../components/ButtonReserve/ButtonReserve';
import Reserve from '../components/Reserve/Reserve';

import { Helmet } from 'react-helmet';
import metaImg from '../assets/default.jpg';

class Home extends Component {
  reserveModal = React.createRef();
closeBtn = React.createRef();
    popUp = React.createRef();
  constructor(props) {
    super(props);
    
    this.state = {
      suite: [],
      reserve: false,
       condition: false,
      loading: true
    };
    

  }


  componentDidMount() {
    document.querySelector('html').style = 'overflow: hidden';
    document.querySelector('body').style = 'overflow: hidden';

    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);

    // lang by default
    if (
      this.props.match.params.lang === 'es' ||
      this.props.match.params.lang === 'en'
    ) {
      this.props.i18n.changeLanguage(this.props.match.params.lang);
    } else {
      this.props.history.push('/home/es');
      return console.log('Lang Not Available');
    }

    const api =
      'https://www.hotelnclapaz.com/restapi/public/api';
      // Pass Content By Lang
      // get rooms
      axios
        .get(`${api}/webroom`)
        .then(response => {
          const res = response.data.result.data[0];
          const content = res.content.en;
          const suite = Object.keys(res.webroomimage).map(function(key) {
            return res.webroomimage[key];
          });
          // Before passit to state extract what I need :)

          this.setState({ suite, content, res });
          console.log('res', res);
          console.log( 'conttent', content);
          console.log( 'suite', suite);
          console.log( 'state!!', this.state);
        })
        .catch(error => {
          console.log(error);
        });

  }

  render() {
    const url = window.location.href;
    const { t } = this.props;
   

    return (
      <React.Fragment>
        <style jsx>
          {`
            html,
            body {
              overflow: hidden;
              position: relative;
            }
          `}
        </style>

        <Loader loading={this.state.loading} />

        <Helmet>
          <title>Home | Hotel NC La Paz</title>
          <meta property="og:title" content="Home | Hotel NC La Paz" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta property="og:image" content={metaImg} />
          <meta
            property="og:description"
            content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
          />
          <meta
            property="og:site_name"
            content="Contact | Hotel NC La Paz"
          />
          <meta itemprop="name" content="Home | Hotel NC La Paz" />
          <meta
            itemprop="description"
            content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
          />
          <meta itemprop="image" content={metaImg} />
        </Helmet>

        <Nav
          match={this.props.match.params.lang}
          history={this.props.history}
        />

        {/* Slider */}
        <section id="top">
          <div id="top-banner">
            <div id="top-banner-background">
              <div id="top-banner-background-mask" />
              <div
                id="top-banner-background-1"
                style={{ width: '1009.4px', zIndex: 4, overflow: 'hidden' }}>
                <div className="active" />
              </div>
              <div
                id="top-banner-background-2"
                style={{ width: '100%', zIndex: 3 }}>
                <div className="active" />
              </div>
              <div
                id="top-banner-background-3"
                style={{ width: '100%', zIndex: 2 }}>
                <div className="" />
              </div>
              <div
                id="top-banner-background-4"
                style={{ width: '100%', zIndex: 1 }}>
                <div className="" />
              </div>
            </div>
            <div id="top-banner-center" style={{ opacity: 1 }}>
              <h1
                style={{
                  display: 'block',
                  margin: '0 auto',
                  maxWidth: '550px'
                }}>
                {t('home.title')}
              </h1>
             
              <ButtonReserve  />
            </div>
          </div>
        </section>
        { (this.state.suite.length > 0)? <Reserve price={this.state.res.price} reserve={this.state.reserve}reserveModal={this.reserveModal}suite={this.state.res}match={this.props.match.params.lang}/>:null}
      </React.Fragment>
    );
  }
}

export default withNamespaces('translation')(Home);
