import React, { Component } from 'react';
import './Nav.scss';
import '../../scss/general.scss';

// Icons from fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBars, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';

// HTTP Request
import axios from 'axios';

// Routing
import { Link } from 'react-router-dom';

// Components
import CardBox from '../CardBox/CardBox';
import CardBoxContent from '../CardBox/CardBoxContent';

// Assets
import logo from '../../assets/logo.svg';
import usaFlag from '../../assets/usa.svg';
import spainFlag from '../../assets/spain.svg';

import { withNamespaces } from 'react-i18next';

library.add(faBars, faInstagram, faFacebookF, faChevronRight);

class Nav extends Component {
  english = React.createRef();
  spanish = React.createRef();

  menuItems = React.createRef();
  blankContent = React.createRef();
  roomContent = React.createRef();
  serviceContent = React.createRef();
  promoContent = React.createRef();
  blogContent = React.createRef();

  state = {
    condition: false,
    rooms: [],
    services: [],
    offers: [],
    blog: []
  };

  componentDidMount() {
    const api =
      'https://www.hotelnclapaz.com/restapi/public/api';
      //'https://www.hotelnclapaz.com/restapi/public/api';
    // get rooms
    axios
      .get(`${api}/webroom`)
      .then(response => {
        const rooms = response.data.result.data;
        this.setState({ rooms });
      })
      .catch(error => {
        console.log(error);
      });

    // get services
    axios
      .get(`${api}/webservice`)
      .then(response => {
        const services = response.data.result.data;
        this.setState({ services });
      })
      .catch(error => {
        console.log(error);
      });

    // get offers
    axios
      .get(`${api}/weboffer`)
      .then(response => {
        const offers = response.data.result.data;
        this.setState({ offers });
      })
      .catch(error => {
        console.log(error);
      });

    // get blog
    axios
      .get(`${api}/webblog`)
      .then(response => {
        const blog = response.data.result.data;
        this.setState({ blog });
      })
      .catch(error => {
        console.log(error);
      });

    if (this.props.match === 'es') {
      // remove if any
      this.english.current.classList.remove('is-english');
      // add it
      this.spanish.current.classList.add('is-spanish');
    }

    if (this.props.match === 'en') {
      // remove if any
      this.spanish.current.classList.remove('is-spanish');
      // add it
      this.english.current.classList.add('is-english');
    }
  }

  // Go to function
  goTo = (event, nameId, route) => {
    event.preventDefault();
    // change the page to /rooms/whaterver
    window.location = `/${route}/${this.props.match}/${nameId}`;
    // this.props.history.push(`/${route}/${this.props.match}/${nameId}`);
  };

  goBack = () => {
    this.menuItems.current.style = 'display: block';

    if (this.blankContent.current.classList.contains('hover--active')) {
      this.blankContent.current.classList.remove('hover--active');
    }
    if (this.roomContent.current.classList.contains('hover--active')) {
      this.roomContent.current.classList.remove('hover--active');
    }
    if (this.serviceContent.current.classList.contains('hover--active')) {
      this.serviceContent.current.classList.remove('hover--active');
    }
    if (this.blogContent.current.classList.contains('hover--active')) {
      this.blogContent.current.classList.remove('hover--active');
    }
    if (this.promoContent.current.classList.contains('hover--active')) {
      this.promoContent.current.classList.remove('hover--active');
    }
  };

  handleClick = () => {
    this.setState({ condition: !this.state.condition });
  };

  onHover = (event, ref) => {
    const screen = window.matchMedia('(max-width: 768px)');
    if (screen.matches) {
      return;
    } else {
      if (this.blankContent.current.classList.contains('hover--active')) {
        this.blankContent.current.classList.remove('hover--active');
      }
      if (this.roomContent.current.classList.contains('hover--active')) {
        this.roomContent.current.classList.remove('hover--active');
      }
      if (this.serviceContent.current.classList.contains('hover--active')) {
        this.serviceContent.current.classList.remove('hover--active');
      }
      if (this.blogContent.current.classList.contains('hover--active')) {
        this.blogContent.current.classList.remove('hover--active');
      }
      if (this.promoContent.current.classList.contains('hover--active')) {
        this.promoContent.current.classList.remove('hover--active');
      }
      ref.current.classList.add('hover--active');
    }
  };

  onClickMobile = (event, ref) => {
    const screen = window.matchMedia('(max-width: 768px)');

    if (screen.matches) {
      this.menuItems.current.style = 'display: none';
      ref.current.classList.add('hover--active');
    } else {
      return;
    }
  };

  render() {
    const { t, i18n } = this.props;

    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
      window.location = `/home/${lng}`;
      this.props.history.push(`/home/${lng}`);
    };

    return (
      <React.Fragment>
        {/* Menu */}
        <nav className="topbar">
          <div className="topbar--left">
            <button
              className={
                this.state.condition
                  ? 'hamburger hamburger--squeeze is-active'
                  : 'hamburger hamburger--squeeze'
              }
              onClick={this.handleClick}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>

            <button className="btn lang" onClick={() => changeLanguage('es')}>
              <img src={spainFlag} ref={this.spanish} alt="Spanish Flag" />
            </button>

            <button className="btn lang" onClick={() => changeLanguage('en')}>
              <img src={usaFlag} ref={this.english} alt="United States Flag" />
            </button>
          </div>

          <img
            className="topbar--logo"
            src={logo}
            alt="Logo Hotel NC - La Paz"
          />

          <div className="topbar--right">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/hotelmitrulp/"
            >
              <span className="icons">
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </span>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/hotel.mitru/"
            >
              <span className="icons">
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </span>
            </a>
          </div>
        </nav>

        {/* Menu Links */}
        <div
          className={
            this.state.condition ? 'overlay__menu is-active' : 'overlay__menu'
          }
        >
          <div className="menu__items" ref={this.menuItems}>
            <ul>
              <li
                onMouseEnter={e => this.onHover(e, this.blankContent)}
                ref={this.blankContent}
              >
                <a href={`/home/${this.props.lng}`}>{t('nav.home')}</a>
              </li>

              <li
                onMouseEnter={e => this.onHover(e, this.roomContent)}
                onClick={e => this.onClickMobile(e, this.roomContent)}
                ref={this.roomContent}
              >
                {t('nav.rooms')}
                <span className="icon-right">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </span>
              </li>

              <li
                onMouseEnter={e => this.onHover(e, this.serviceContent)}
                onClick={e => this.onClickMobile(e, this.serviceContent)}
                ref={this.goToService}
              >
                {t('nav.services')}
                <span className="icon-right">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </span>
              </li>

              <li
                onMouseEnter={e => this.onHover(e, this.promoContent)}
                onClick={e => this.onClickMobile(e, this.promoContent)}
                ref={this.promoContent}
              >
                {t('nav.offers')}
                <span className="icon-right">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </span>
              </li>

              <li
                onMouseEnter={e => this.onHover(e, this.blogContent)}
                onClick={e => this.onClickMobile(e, this.blogContent)}
                ref={this.blogContent}
              >
                {t('nav.blog')}
                <span className="icon-right">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </span>
              </li>

              <li
                onMouseEnter={e => this.onHover(e, this.blankContent)}
                ref={this.blankContent}
              >
                <Link to={`/contact/${this.props.lng}`}>
                  {t('nav.contact')}
                </Link>
              </li>

              <li>
                <button
                  className="btn lang__responsive"
                  onClick={() => changeLanguage('es')}
                >
                  <img src={spainFlag} alt="Spanish Flag" />
                </button>

                <button
                  className="btn lang__responsive"
                  onClick={() => changeLanguage('en')}
                >
                  <img src={usaFlag} alt="United States Flag" />
                </button>
              </li>
            </ul>
          </div>

          {/* Menu Content */}
          <div className="menu__content room__content" ref={this.roomContent}>
            <CardBox
              array={this.state.rooms}
              goTo={this.goTo}
              route={'rooms'}
              lang={this.props.match}
              menuItemsRef={this.menuItems}
              goBack={this.goBack}
            />
          </div>

          <div
            className="menu__content service__content"
            ref={this.serviceContent}
          >
            <CardBox
              array={this.state.services}
              goTo={this.goTo}
              route={'services'}
              lang={this.props.match}
              menuItemsRef={this.menuItems}
              goBack={this.goBack}
            />
          </div>

          <div
            className="menu__content offers__content"
            ref={this.promoContent}
          >
            <CardBoxContent
              array={this.state.offers}
              goTo={this.goTo}
              route={'offers'}
              lang={this.props.match}
              menuItemsRef={this.menuItems}
              goBack={this.goBack}
            />
          </div>

          <div className="menu__content blog__content" ref={this.blogContent}>
            <CardBoxContent
              array={this.state.blog}
              goTo={this.goTo}
              route={'blog'}
              lang={this.props.match}
              menuItemsRef={this.menuItems}
              goBack={this.goBack}
            />
          </div>
          {/* End Menu Content */}
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces('translation')(Nav);
