import React, { Component } from 'react';

import Nav from '../components/Nav/Nav';
import Loader from '../components/Loader/Loader';

// HTTP Request
import axios from 'axios';
import SlideImage from '../components/SlideImage';

import { withNamespaces } from 'react-i18next';

// Components
import PopUp from '../components/PopUp/PopUp';
import ReservePromo from '../components/Reserve/ReservePromo';

import { Helmet } from 'react-helmet';

class Offers extends Component {
  reserveModal = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      offer: [],
      reserve: false,
      loading: false
    };
  }

  componentDidMount() {
    document.querySelector('html').style = 'overflow: hidden';
    document.querySelector('body').style = 'overflow: hidden';

    // lang by default
    if (
      this.props.match.params.lang === 'es' ||
      this.props.match.params.lang === 'en'
    ) {
      this.props.i18n.changeLanguage(this.props.match.params.lang);
    } else {
      this.props.history.push('/home/es');
      return console.log('Lang Not Available');
    }

    const api =
      'https://www.hotelnclapaz.com/restapi/public/api';
    // Pass Content By Lang
    if (this.props.match.params.lang === 'en') {
      // get rooms
      axios
        .get(`${api}/weboffer`)
        .then(response => {
          const res = response.data.result.data.find(
            item =>
              item.title[this.props.match.params.lang] ===
              this.props.match.params.offer
          );
          const desc = res.description.en;
          const offer = Object.keys(res.webofferimage).map(function(key) {
            return res.webofferimage[key];
          });
          // Before passit to state extract what I need :)
          this.setState({ offer, res, desc });
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (this.props.match.params.lang === 'es') {
      // get rooms
      axios
        .get(`${api}/weboffer`)
        .then(response => {
          const res = response.data.result.data.find(
            item =>
              item.title[this.props.match.params.lang] ===
              this.props.match.params.offer
          );
          console.log('es', response);
          const desc = res.description.es;
          const offer = Object.keys(res.webofferimage).map(function(key) {
            return res.webofferimage[key];
          });
          // Before passit to state extract what I need :)
          this.setState({ offer, res, desc });
          console.log('this.state', this.state);
        })
        .catch(error => {
          console.log(error);
        });
    }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 0);
  }

  render() {
    const url = window.location.href;

    if (!this.state.offer.length > 0) {
      return <div />;
    }

    if (this.state.loading) {
      return <Loader loading={this.state.loading} />;
    }

    return (
      <React.Fragment>
        <style jsx="true">
          {`
            html,
            body {
              overflow: hidden;
              position: relative;
            }
          `}
        </style>
        <Helmet>
          <title>{this.props.match.params.offer} | Hotel NC La Paz</title>

          {/* SEO tags */}
          <meta
            property="og:title"
            content={`${this.props.match.params.offer} | Hotel NC La Paz`}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta
            property="og:image"
            content={`https://www.hotelnclapaz.com/restapi/public${
              this.state.offer[0].image
            }`}
          />
          <meta
            property="og:description"
            content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
          />
          <meta
            property="og:site_name"
            content={`${this.props.match.params.offer} | Hotel NC La Paz`}
          />

          <meta
            itemprop="name"
            content={`${this.props.match.params.offer} | Hotel NC La Paz`}
          />
          <meta
            itemprop="description"
            content="Hotel NC ubicado en el centro La Paz - Bolivia, con un estilo moderno, brinda estadía tranquila y placentera. Habitaciones Standard y Suite, desayuno buffet, restaurante, business center, garaje."
          />
          <meta
            itemprop="image"
            content={`https://www.hotelnclapaz.com/restapi/public${
              this.state.offer[0].image
            }`}
          />
        </Helmet>

        <Nav
          match={this.props.match.params.lang}
          history={this.props.history}
        />

        <section className="body">
          <h4 className="roomName">{this.props.match.params.offer}</h4>
          <PopUp
            reserveModal={this.reserveModal}
            content={this.state.desc}
             contenall={this.state.res}
            button={true}
          />
          <ReservePromo
            price={this.state.res.price}
            reserve={this.state.reserve}
            reserveModal={this.reserveModal}
            match={this.props.match.params.lang}
          />
          <SlideImage data={this.state.offer} />
        </section>
      </React.Fragment>
    );
  }
}

export default withNamespaces('translation')(Offers);
